/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components_v2/seo'
import LayoutV2 from '../components_v2/Layout'
import { Article, Container } from '../styles/Job.style'

const JobPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <LayoutV2>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        keywords={post.frontmatter.tags}
        location={post.fields.slug}
        image={post.frontmatter.image.childImageSharp.fluid.src}
      />
      <Container>
        <Article>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </Article>
      </Container>
    </LayoutV2>
  )
}

export default JobPostTemplate
export const pageQuery = graphql`
  query JobPost {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { type: { eq: "job" } }) {
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        description
        type
        image {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
