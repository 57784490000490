import styled from 'styled-components'

export const Container = styled.div`
  max-width: 80ch;
  margin: 3rem auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
`
export const Article = styled.article`
  background: #ffffff;
  padding: 1rem;
  color: ${({ theme: { colors } }) => colors.gray};
  h2 {
    color: ${({ theme: { colors } }) => colors.primary};
    text-align: center;
  }
  h3 {
    color: ${({ theme: { colors } }) => colors.primary};
  }
  a {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.primary};
    padding-bottom: 2px;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.primary};
    transition: 0.2s ease-in;
    :hover {
      color: ${({ theme: { colors } }) => colors.red};
      border-bottom: 2px solid ${({ theme: { colors } }) => colors.red};
    }
  }
  p > strong {
    color: ${({ theme: { colors } }) => colors.red};
  }
`
